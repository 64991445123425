// import styled from 'styled-components';

// export const JobWrapper = styled.section`
//   /* padding: 100px 0 0 0;*/
// `;
// JobWrapper.displayName = 'JobWrapper';

export const SpeakerWidgetStyles = {
  errorText: {
    color: 'red',
  },
};

// export const JobWidgetStyles = {
// }

// export default JobWrapper;
