import styled from 'styled-components';

export const EpisodeSearchWrapper = styled.section`
  /* padding: 100px 0 0 0;*/
`;
EpisodeSearchWrapper.displayName = 'EpisodeSearchWrapper';

export const Styles = {
  row: {
    flexBox: true,
    flexWrap: 'wrap',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    mt: '10px',
    mb: '10px',
  },
};

export default EpisodeSearchWrapper;
