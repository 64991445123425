/**
 * Handler for input events for New Job Form
 *
 * This is where we call our custom API to manage Job requests
 */

import { doFetch as podstacksAPI } from '../../../../api/PodstacksAPI';
import { getUserId } from '../../../../api/UserLoginAPI';

// Update speaker names and number of speakers
export const updateSpeakerObj = (rawSpeakers) => {
  let speakers = [];
  if (rawSpeakers !== '' && rawSpeakers.split('\n').length > 0){
    let splitSpeakers = rawSpeakers.split('\n');
    // HACK: Add index to beginning of each speakername to preserve order
    splitSpeakers.map((speaker, index) => {
      speakers.push(index+" "+speaker)
    })
    // speakers = rawSpeakers.split('\n');
  }
  return {
    raw: rawSpeakers,
    speakers,
    numSpeakers: speakers.length,
  };
};

// Add job information to database, and do redirect to job page
export const addJob = async (jobObj) => {
  console.log('Adding new job: ' + jobObj.episode.title);
  const userId = getUserId();
  const { episode } = jobObj;

  // Simplified API fetch
  // const doFetch = async ( apiName, apiEndpoint, params = null, query = null, body = null, cb = null, errCb = null )
  const newResults = await podstacksAPI(
    'jobs',
    'createJob',
    null,
    null,
    {
      job: {
        jobId: episode.id, // Job ID is also the ListenNotes API episode_id
        userId, // This maps to the ListenNotes API podcast_id
        status: 'JOB ADDED', // Job status
        paymentStatus: 'UNPAID',
        payment: {},
        urls: { audio: episode.audio }, // (OBJ) URL object to various job resources (blog post, downloadable transcripts, etc)
        awstJob: {}, // (OBJ) AWS Transcribe job settings object
        episode: episode, // (OBJ) Podcast data episode object
        customDict: jobObj.customDict, // Custom dictionary for transcription
        audioSupport: episode.audioSupport, // (OBJ) Audio support object
        speakerNames: jobObj.speakerObj.speakers, // Speaker names, can parse number of speakers as needed
        exportFormats: 'md html pdf'.split(' '), // Output formats to generate for transcript
      },
    },
    null,
    null
  );

  // Update the user jobs array
  const user = await podstacksAPI(
    'users',
    'getUser',
    { userId },
    null,
    null,
    null,
    null,
    null
  );
  let { jobs } = user;
  // console.log(jobs)
  if (Array.isArray(jobs)) {
    jobs.push(episode.id);
  } else {
    jobs = [episode.id];
  }
  // console.log(jobs)
  await podstacksAPI(
    'users',
    'updateUser',
    { userId },
    null,
    { user: { jobs } },
    null,
    null
  );
};

// Generate email link
export const buildDebugEmail = (episode) => {
  const { audioSupport } = episode;
  const {
    aliasSupported,
    extSupported,
    file,
    finalUrl,
    mime,
    parsedExt,
  } = audioSupport;
  const destEmail = 'neoesearhole@gmail.com';
  const subject = 'PODSTACKS AUDIO SUPPORT ERROR: ' + episode.id;
  const body =
    'USER ID: ' +
    getUserId() +
    '%0A%0A\
  EPISODE ID: ' +
    episode.id +
    '%0A%0A\
  DEBUG INFO:  %0A%0A\
  aliasSupported: ' +
    aliasSupported +
    '%0A\
  extSupported: ' +
    extSupported +
    '%0A\
  file: ' +
    encodeURI(file) +
    '%0A\
  mime: ' +
    encodeURI(mime) +
    '%0A\
  parsedExt: ' +
    parsedExt +
    '%0A\
  ';
  // finalUrl: "+encodeURI(finalUrl)+"%0A\
  // console.log({ finalUrl, encoded: encodeURI(finalUrl)})
  return `mailto:${destEmail}?\subject=${subject}&body=${body}`;
};

// export const InputHandler = {
//   addJob,
//   updateSpeakerObj,
//   redirectToPage,
//   buildDebugEmail,
// };
