// REACT AND GATSBY
import React from 'react';
// ELEMENTS AND COMPONENTS
import Box from 'reusecore/src/elements/Box';
// STYLING
import { WidgetContainerStyles } from './widget.styles';
// LOGIC AND HANDLERS
// NPM MODULES

// Container that holds multiple widgets
const WidgetContainer = props => {
  // console.log(props)
  const { row } = WidgetContainerStyles;
  if (props.width) row.width = props.width;
  return (
    <Box className="widget_container" {...row} styles={{ height: '100%' }}>
      {props.children}
    </Box>
  );
};

export default WidgetContainer;
