// REACT AND GATSBY
import React, { useState } from 'react';
// ELEMENTS AND COMPONENTS
import Box from 'reusecore/src/elements/Box';
import CheckBox from 'reusecore/src/elements/Checkbox';
import Button from 'reusecore/src/elements/Button';
import { Formik } from 'formik';
// import Image from 'reusecore/src/elements/Image';
import Input from 'reusecore/src/elements/Input';
import { SpeakerWidget } from 'common/src/components/JobWidgets/SpeakerWidget';
import WidgetLayout from 'reusecore/src/elements/Widget/WidgetLayout';
// STYLING
import { Styles } from './newTsxJobWidgets.styles'; // CSS styling
// LOGIC AND HANDLERS
import { addJob, buildDebugEmail, updateSpeakerObj } from './handlers'; // Code to handle user I/O
import { redirectToPage } from '../../../../api/BrowserAPI';
// NPM MODULES

// Speaker input form widget
export const SpeakersFormWidget = ({ heading, episode }) => {
  const [numSpeakers, updateNumSpeakers] = useState(0);

  const { row, widgetsRow } = Styles;
  const maxSpeakers = 3;
  const { audioSupport } = episode;
  return (
    <WidgetLayout title={heading} width={[1, 1, 3 / 4, 3 / 4, 3 / 4]}>
      {audioSupport && audioSupport.extSupported ? (
        <>
          <Formik
            initalValues={{ speakers: 'poop' }}
            onSubmit={(values, { setSubmitting, resetForm }) => {
              setSubmitting(true);
              const c = window.confirm('Create new transcript job?');
              if (c) {
                setTimeout(() => {
                  // console.log(values);
                  const customDict = ' ';
                  const speakerObj = updateSpeakerObj(
                    values.speakers
                  );
                  const jobObj = { episode, speakerObj, customDict };
                  addJob(jobObj);
                  // console.log(jobObj)
                  // alert('Values submitted:' + JSON.stringify(values, null, 2));
                  // resetForm()
                  alert('New transcript job created!');
                  redirectToPage('users/job', {
                    jobId: episode.id,
                  });
                  setSubmitting(false);
                }, 2000);
              }
            }}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              isSubmitting,
            }) => (
              <form onSubmit={handleSubmit}>
                {/* {JSON.stringify(values)} */}
                <Box {...widgetsRow}>
                  <WidgetLayout
                    title="Enter Speaker Names"
                    width={[1, 1, 1, 1 / 3, 1 / 3]}
                  >
                    <p>
                      Enter each speaker name in order of appearance, one per
                      line.
                    </p>
                    <Input
                      // size="25"
                      rows="6"
                      cols="20"
                      inputType="textarea"
                      label="Speakers"
                      isMaterial={true}
                      isFormik={true}
                      value={values.speakers}
                      onChange={(e) => {
                        handleChange(e);
                        if (
                          e.target.value !== '' &&
                          e.target.value.split('\n').length > 0
                        )
                          updateNumSpeakers(e.target.value.split('\n').length);
                        else updateNumSpeakers(0);
                      }}
                      onBlur={handleBlur}
                      style={{ color: 'black' }}
                      // onChange={value =>
                      //   updateSpeakerObj(InputHandler.updateSpeakerObj(value))
                      // }
                    />
                    <p>
                      <strong>
                        You may have up to {maxSpeakers} speakers maxium.
                      </strong>
                    </p>
                  </WidgetLayout>

                  <SpeakerWidget
                    rawSpeakerNames={values.speakers}
                    maxSpeakers={maxSpeakers}
                  />

                  <WidgetLayout
                    title="Advanced Options"
                    width={[1, 1, 1, 1 / 3, 1 / 3]}
                  >
                    <p>Not available</p>
                    {/* <CheckBox
              id='introVoice'
              labelText='This episode has an intro speaker/voice'
              isMaterial={false}
              // value={values.introVoice}
              // onChange={handleChange}
              />  */}
                  </WidgetLayout>
                </Box>
                <Box {...row}>
                  {numSpeakers <= maxSpeakers && numSpeakers != 0 && (
                    <Button
                      title="SAVE"
                      style={{ width: '75%' }}
                      type="submit"
                      disabled={isSubmitting}
                      isLoading={isSubmitting}
                    />
                  )}
                </Box>
              </form>
            )}
          </Formik>
        </>
      ) : (
        <>
          <h3>ERROR: AUDIO FILE TYPE IS NOT SUPPORTED</h3>
          <p>Please notify support with the debug information below.</p>
          <a href={buildDebugEmail(episode)}>
            <Button title="Contact Support" />
          </a>
          <h4>DEBUG INFO</h4>
          <p>{JSON.stringify(audioSupport, null, 2)}</p>
        </>
      )}
    </WidgetLayout>
  );
};
