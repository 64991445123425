// REACT AND GATSBY
import React from 'react';
// ELEMENTS AND COMPONENTS
// import Button from 'reusecore/src/elements/Button'
import WidgetLayout from 'reusecore/src/elements/Widget/WidgetLayout';
// STYLING
import { SpeakerWidgetStyles } from './jobWidgets.styles';
// LOGIC AND HANDLERS
// NPM MODULES
import Dotdotdot from 'react-dotdotdot';

// Speaker Names Widget
export const SpeakerWidget = ({ rawSpeakerNames, speakerNames,  maxSpeakers }) => {
  let speakers = null
  if (speakerNames && speakerNames.length > 0) {
    speakers = speakerNames
  } else if ( rawSpeakerNames && rawSpeakerNames.split('\n').length > 0 ){
    speakers = rawSpeakerNames.split('\n')
  } else { 
    speakers=[]
  }
  // console.log(speakers)
  const { errorText } = SpeakerWidgetStyles
  return (
  <WidgetLayout title="Speaker List">

    {(speakers && speakers.length <= maxSpeakers) ?
    <p>
      <strong>
        {speakers.length} speakers
      </strong>
    </p>:
    <p style={errorText}>
      <strong>Invalid number of speakers!</strong>
    </p>
    }

    {(speakers && (speakers.length < maxSpeakers + 1)) ?
      (speakers.map((speaker, index) => 
        (index < maxSpeakers) ?
          <Dotdotdot clamp={2} key={index}>
            <p key={index}>
              <strong>Speaker {index}:</strong> {speaker}
            </p>
          </Dotdotdot>:<></>
      )):<></>
    }
  </WidgetLayout>
  );

};
