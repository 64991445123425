// REACT AND GATSBY
import React from 'react';
// ELEMENTS AND COMPONENTS
import Box from 'reusecore/src/elements/Box';
import Button from 'reusecore/src/elements/Button';
import WidgetLayout from 'reusecore/src/elements/Widget/WidgetLayout';
import EpisodeTile from 'common/src/components/InfoTile/episodeTile'
// STYLING
import { Styles } from './newTsxJobWidgets.styles'
// LOGIC AND HANDLERS
// NPM MODULES

// Selected episode widget
export const SelectedEpisodeWidget = ({ title, episode, onBackClickCB }) => {
  const { row } = Styles
  return (
    <WidgetLayout 
      title={title}
      width={[1, 1, 3/4, 3/4, 3/4]}
    >
      <EpisodeTile
        tileStyle="large"
        fullInfo={true}
        episode={episode}
      />
      <Box {...row}>
        <Button
          title="<< GO BACK"
          onClick= { () => { onBackClickCB() }}
        />
        {/* <Button
          title="CONTINUE >>"
          onClick= { () => { onContinueClickCB() }}
        /> */}
      </Box>
    </WidgetLayout>
  );
};
