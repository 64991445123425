/**
 * Handler for input events for EpisodeSearch
 *
 * This is where we call our custom API to manage Job requests
 */

import { doFetch as podstacksAPI } from '../../../../api/PodstacksAPI';
import { pageTimeout } from '../../../../api/BrowserAPI';
import { getUserId } from '../../../../api/UserLoginAPI'
import { trackPromise } from 'react-promise-tracker';


// Loads initial and subsequent podcast episode results
export const loadEpisodes = async (searchData, next = true) => {

  // let moreEpisodes = null
  // pageTimeout( () => { return moreEpisodes == null })
  // moreEpisodes = await trackPromise(loadEpisodes( searchData, false ))
  // if (moreEpisodes)
  //   setSearchData(moreEpisodes)

  let message = null;
  pageTimeout( () => { return message == null })
  // const query = getUrlParams();
  const query = { userId: getUserId()}
  // console.log({query})
  if (next) {
    message = await trackPromise(getResults(searchData.query, searchData.message))
  } else {
    message = await trackPromise(getResults(query, {
      prev_episode_pub_date: -1,
      next_episode_pub_date: 0,
      episodes: [],
    }));
  }
  return {query, message, epLoaded: true}
};

// Get podcast search results (new)
const getResults = async (query, prevResults) => {
  console.log({ query, prevResults });
  const { prev_episode_pub_date, next_episode_pub_date } = prevResults;

  if (query.userId !== ' ' && prev_episode_pub_date !== next_episode_pub_date) {
    console.log('Loading episodes for podcast: ' + query.userId);
    // Simplified API fetch
    const newResults = await podstacksAPI(
      'podcasts',
      'getPodcastEpisodes',
      { podcast_id: query.userId },
      { next_episode_pub_date },
      null,
      null,
      null
    );
    // If new/first query, return without appending results
    if (next_episode_pub_date == 0) {
      newResults.prev_episode_pub_date = prevResults.next_episode_pub_date;
      return await newResults;
    } else {
      // If subsequent query, return appended results
      prevResults.prev_episode_pub_date = prevResults.next_episode_pub_date;
      // Check if offset is null (POSSIBLE LISTENNOTES API BUG)
      prevResults.next_episode_pub_date = newResults.next_episode_pub_date
        ? newResults.next_episode_pub_date
        : prevResults.next_episode_pub_date;
      newResults.episodes.map(async episode => {
        await prevResults.episodes.push(episode);
      });
      return await prevResults;
    }
  } else {
    return prevResults;
  }
};

// export const InputHandler = {
//   loadEpisodes,
//   redirectToPage,
// };
