// import styled from 'styled-components';

// export const JobWrapper = styled.section`
//   /* padding: 100px 0 0 0;*/
// `;
// JobWrapper.displayName = 'JobWrapper';

export const WidgetLayoutStyles = {
  col: {
    // width: [1, 1, 1, 1 / 3, 1 / 3],
    width: 3/4,
    pb: ['10px', '10px', '20px', '20px'],
    pr: ['10px', '10px', '20px', '20px'],
  },
};

export const WidgetContainerStyles = {
  row: {
    flexBox: true,
    flexWrap: 'wrap',
    // flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    mt: '10px',
    mb: '10px',
  },
}

export const Styles = {
  row: {
    flexBox: true,
    flexWrap: 'wrap',
    alignItems: 'center',
    justifyContent: 'center',
    mt: '10px',
    mb: '10px',
  },
  col: {
    height: '100%',
    width: [1, 1, 1, 1 / 3, 1 / 3],
    pb: ['10px', '10px', '20px', '20px'],
    pr: ['10px', '10px', '20px', '20px'],
  },
  errorText: {
    color: 'red',
  },
  widgetsRow: {
    flexBox: true,
    flexWrap: 'wrap',
    mt: '10px',
    mb: '10px',
  },
};
// export default JobWrapper;
