// REACT AND GATSBY IMPORTS
import React, { useState } from 'react';
// STYLING
// CONTAINERS
// import BannerSection from '../../containers/SaasClassic/Banner'
// COMPONENTS (landing-gatsby)
import UsersLayout from 'gatsby-landing/src/components/UsersLayout';
// COMMON COMPONENTS
import WidgetContainer from 'reusecore/src/elements/Widget/WidgetContainer';
import { EpisodeSelectWidget } from 'common/src/components/NewTranscriptWidgets/EpisodeSelectWidget';
import { SelectedEpisodeWidget } from 'common/src/components/NewTranscriptWidgets/SelectedEpisodeWidget';
import { SpeakersFormWidget } from 'common/src/components/NewTranscriptWidgets/SpeakersFormWidget';
// ELEMENTS
// LOGIC
import { doFetch as podstacksAPI } from '../../../../api/PodstacksAPI';
import { getUserId } from '../../../../api/UserLoginAPI';

// User interface for creating a new job
const UsersCreateNewJobPage = () => {
  const [stage, updateStage] = useState(0);
  const [episode, updateEpisode] = useState(null);
  const stages = [
    {
      title: 'Step 1: Select Your Episode',
      description: 'default',
    },
    {
      title: 'Step 2: Edit Transcript Settings',
      description: 'default',
    },
  ];
  return (
    <UsersLayout
      seo={{ title: stages[stage].title }}
      heading={stages[stage].title}
    >
      <WidgetContainer>
        {stage == 0 ? (
          <>
            <EpisodeSelectWidget
              title={stages[stage].title}
              onTileClick={async (episode) => {
                const job = await podstacksAPI('jobs', 'getJob', {
                  userId: getUserId(),
                  jobId: episode.id,
                });
                if (!job) {
                  updateEpisode(
                    await podstacksAPI('podcasts', 'getEpisodeData', {
                      episode_id: episode.id,
                    })
                  );
                  updateStage(1);
                } else {
                  alert('ERROR: Job already exists!');
                  updateStage(0);
                }
                // updateEpisode(episode)
                // console.log({episode})
              }}
            />
          </>
        ) : (
          <></>
        )}
        {stage == 1 && episode ? (
          <>
            <SelectedEpisodeWidget
              title="Selected Episode"
              episode={episode}
              onBackClickCB={() => {
                updateStage(0);
                updateEpisode(null);
              }}
            />
            <SpeakersFormWidget
              heading={stages[stage].title}
              episode={episode}
            />
          </>
        ) : (
          <></>
        )}
      </WidgetContainer>
    </UsersLayout>
  );
};

export default UsersCreateNewJobPage;
