// REACT AND GATSBY
import React, { useState } from 'react';
// ELEMENTS AND COMPONENTS
import Button from 'reusecore/src/elements/Button';
import WidgetLayout from 'reusecore/src/elements/Widget/WidgetLayout';
import EpisodeSearch from 'common/src/components/EpisodeSearch';
// STYLING
// LOGIC AND HANDLERS
// NPM MODULES

// Episode select widget
export const EpisodeSelectWidget = ({title,  onTileClick }) => {
  const [ loadEpisodes, updateLoadEpisodes ] = useState(false)

  return (
    <WidgetLayout 
      title={title}
      width={[1, 1, 3/4, 3/4, 3/4]}
    >
      { (loadEpisodes) ?
      <><EpisodeSearch 
        redirectObj={{ 
          onImageClickCB: onTileClick,
          onTitleClickCB: onTileClick
        }}
        />
      </>:
      <>
        <Button
        title="Load Episodes"
        onClick= { () => {
          updateLoadEpisodes(true)
        }}
      /></>
      }
    </WidgetLayout>
  );
};
