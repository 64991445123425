// REACT AND GATSBY
import React, { useState, useEffect } from 'react';
// ELEMENTS AND COMPONENTS
import Box from 'reusecore/src/elements/Box';
import Button from 'reusecore/src/elements/Button';
import Spinner from 'reusecore/src/elements/Spinner'
import Container from 'common/src/components/UI/Container';
import EpisodeTile from 'common/src/components/InfoTile/episodeTile';
// import ErrorSec from 'gatsby-landing/src/containers/Error';
// STYLING
import EpisodeSearchWrapper, { Styles } from './episodeSearch.styles';
// LOGIC AND HANDLERS
import { loadEpisodes } from './handlers';
// NPM MODULES


// Episode search tool for a single podcast
// const EpisodeSearch = ({ heading, redirectPath }) => {
const EpisodeSearch = ({ heading, redirectObj }) => {
  const [searchData, setSearchData] = useState({
    epLoaded: false,
    query: {
      userId: ' ',
    },
    message: {
      prev_episode_pub_date: -1,
      next_episode_pub_date: 0,
      episodes: [],
    },
  });

  // const [items, updateItems] = useState([])

  // Performs initial fetch of episode objects when component loads
  useEffect(() => {
    async function initialLoad() {
      setSearchData(await loadEpisodes(searchData, false));
    }
    if (!searchData.epLoaded) initialLoad();
  }, []);

  // const episodes = [];
  const items = []
  const { row } = Styles;
  const { prev_episode_pub_date, next_episode_pub_date } = searchData.message;

  // Need to check data before pushing to array
  if (searchData.message.episodes && searchData.message.episodes.length > 0) {
    // const episodes = items
    searchData.message.episodes.map(episode => {
      //Override query params in redirect object
      redirectObj.queryParams = { jobId: episode.id }
      items.push(
        <Box key={episode.id}>
          {/* <EpisodeTile episode={episode} redirectPath={redirectPath} /> */}
          <EpisodeTile
            tileStyle="small"
            episode={episode}
            redirectObj={redirectObj}
          />
        </Box>
      )
      // updateItems(episodes)
    });
  }

  // console.log({ searchData, items })

  // Display results if found.  Display error otherwise
  if (items.length > 0) {
    return (
      <EpisodeSearchWrapper>
        <Container className="episode_search_container">
          {/* ** TITLE BAR (optional) ** */}
          <Box {...row}>
            <h1>{heading}</h1>
          </Box>

          {/*** SEARCH RESULTS META ***/}
          <Box {...row}>
            <h3>{searchData.message.title}</h3>
            {/* </Box>
        <Box {...row}> */}
            <h4>{searchData.message.total_episodes + ' total episodes'}</h4>
          </Box>

          {/*** GEWGLE STYLE LOAD RESULTS ***/}
          <Box {...row}>{items}</Box>

          {/*** LOAD MORE RESULTS BUTTON ***/}
          {prev_episode_pub_date !== next_episode_pub_date ? (
            <Box {...row}>
              <Spinner />
              <Button
                title="LOAD MORE EPISODES"
                onClick={async () => {
                  setSearchData(
                    await loadEpisodes(searchData, true)
                  )
                }}
              />
            </Box>
          ) : (
            <></>
          )}
        </Container>
      </EpisodeSearchWrapper>
    );
  } else {
    return (
      // DEFAULT ERROR PAGE
      <EpisodeSearchWrapper>
        <Spinner />
        {/* <Container className="episode_search_container">
          <ErrorSec styleType="light" />
        </Container> */}
      </EpisodeSearchWrapper>
    );
  }
};

export default EpisodeSearch;
